import {getLocale} from "@/utility/helper";
import * as L from "leaflet";
// @ts-ignore
import houseIcon from '@/../fontawesome/home-white.svg' // @ts-ignore
import condoIcon from '@/../fontawesome/condo-white.svg'

export const getSearchApi = (queryString: string, layout: string): string => {
  const origin = window.location.protocol + "//" + window.location.host;
  const locale = getLocale();
  return `${origin}/api/${locale}/search?${queryString}&layout=${layout}`
}

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const visibleElement = (id, isShow = false) => {
  const ele = document.getElementById(id);
  if (!ele) return;

  if (isShow) {
    ele?.classList.remove('hidden');
    return
  }

  ele?.classList.add('hidden');
}

// set input value by id
export const setInputValue = (id: string, value: string) => {
  const input = document.getElementById(id) as HTMLInputElement;
  if (!input) return;
  input.value = value || "";
};

// HELPER
export const eventListener = (ele: HTMLElement | null | Window & typeof globalThis, eventType: string, cb: () => void): void => {
  ele?.addEventListener(eventType, cb);
}

/**
 * Size of map and listing
 * mobile will show only listing
 * */
export const isMobile = (): boolean => {
  return window.innerWidth < 768
}

export const showLoadingIndicator = (isShow: boolean, id: string): void => {
  const lists_layout = document.getElementById(id);
  if (!lists_layout) return;

  if (isShow) {
    lists_layout?.classList.add("opacity-50");
    return;
  }

  lists_layout?.classList.remove("opacity-50");
};

export const showIndicatorByClass = (isShow: boolean, className: string): void => {
  const listsLayout = document.getElementsByClassName(className);
  if (!listsLayout) return;
  Array.from(listsLayout).forEach((list) => {
    if (isShow) {
      list.classList.add("hidden");
    } else {
      list.classList.remove("hidden");
    }
  });
};

export const disableButtonIndicatorById = (isDisabled: boolean, id: string): void => {
  const button = document.getElementById(id) as HTMLButtonElement;
  if (!button) return;
  button.disabled = isDisabled;
}

export const clearLabelPrice = () => {
  let minPriceEle = document.getElementById('min-price-label') as HTMLElement;
  let maxPriceEle = document.getElementById('max-price-label') as HTMLElement;
  if (minPriceEle) {
    minPriceEle.innerHTML = 'No Min';
  }
  if (maxPriceEle) {
    maxPriceEle.innerHTML = 'No Max';
  }
}

type searchData = {
  tenure: string,
  location_1: string,
  location_2: string,
  location_3: string,
  types: string,
  min_price: number | string,
  max_price: number | string,
  bedrooms: number | string,
  bathrooms: number | string,
  features: string
}

export const prepareSearchData = (data: any) => {
  return {
    tenure: data.tenure,
    location_1: data.location_1,
    location_2: data.location_2,
    location_3: data.location_3,
    types: data.types,
    min_price: data.min_price,
    max_price: data.max_price,
    bedrooms: data.bedrooms,
    bathrooms: data.bathrooms,
    features: data.features,
    min_interior_size: data.min_interior_size,
    max_interior_size: data.max_interior_size,
    min_land_size: data.min_land_size,
    max_land_size: data.max_land_size,
    place: data.place,
    map: data.map,
  } as searchData
}

export const scrollToTopWhenFilter = () => {
  // Mobile
  if (window.innerWidth <= 767) {
    window.scrollTo({top: 0, behavior: 'smooth'});
  } else {
    // Desktop
    document.getElementById('lists_layout')?.scrollTo({behavior: 'smooth', top: 0})
  }
}

export const measureTask = async (task: Promise<any>, taskName: string) => {
  const startTime = performance.now();
  const result = await task;
  const endTime = performance.now();
  console.log(`${taskName} Execution time: ${endTime - startTime} milliseconds`);
  return result;
}

export const getMapProperties = async (queryString: string) => {
  // const { queryString } = handleFilterUpdate();
  const res = await fetch(getSearchApi(queryString, "map"));
  const mapData = await res.json();
  return mapData?.data || [];
};


export const interactiveListener = (runScript: () => void) => {
  let isLoaded = false

  const initScript = () => {
    if (isLoaded) {
      // Remove both listeners to ensure it works for any device
      document.removeEventListener("mousemove", interactiveListener);
      document.removeEventListener("touchstart", interactiveListener);
      document.removeEventListener("touchmove", interactiveListener);
      return;
    }
    // console.log("runScript")
    isLoaded = true;
    runScript()
  }

  const interactiveListener = () => {
    //console.log("interactiveListener")
    initScript()
  }

  // Add event listeners for both mousemove and touchstart
  document.addEventListener('mousemove', interactiveListener);
  document.addEventListener('touchstart', interactiveListener);
  document.addEventListener('touchmove', interactiveListener);

}

export const getIconMarker = (type: string): string => {
  let icon = "";
  if (type === "house") {
    icon = `<img src="${houseIcon}" height="10" width="10" class="h-[12px]" />`
  } else if (type === 'condo') {
    icon = `<img src="${condoIcon}" height="10" width="10" class="h-[12px]" />`
  }
  return icon;
}

/**
 * Loading template map-card
 * */
export const getLoadingCardTemplate = (): string => {
  return `<div id="map_card_property"><div class="flex m-2 space-x-4 animate-pulse-slow pl-1 pr-3 pt-1">
    <div class="bg-gray-400 h-14 w-14 rounded-md"></div>
    <div class="flex-1 py-1 space-y-4 mr-1">
      <div class="h-2 bg-gray-400 rounded-lg"></div>
      <div class="space-y-3">
        <div class="grid grid-cols-3 gap-4">
          <div class="h-2 col-span-2 bg-gray-400 rounded-lg"></div>
          <div class="h-2 col-span-1 bg-gray-400 rounded-lg"></div>
        </div>
        <div class="h-2 bg-gray-400 rounded-lg"></div>
      </div>
    </div>
  </div></div>`;
};

export const generateClassMarker = (cluster: { propertyId: number; }[]): string => {
  const getPropertyIDs = cluster.map((item) => `mk-${item.propertyId}`);
  return getPropertyIDs.join(' ');
}

export type PopUpType = L.Popup & { propertyID: number };
export type markerType = 'property' | 'group-round-cluster' | 'marker-list-cluster';
/**
 * Calculate the offset of the popup
 * make popup show within map bounds
 * */
export const adjustPopupLatLng = (
  MAP_INSTANCE: L.Map,
  popupLat: number,
  popupLng: number,
  type: markerType = 'property',
  hasProject = false
) => {
  // Get map bounds and popup dimensions
  const markerPos = MAP_INSTANCE.latLngToContainerPoint([popupLat, popupLng]);
  const mapSize = MAP_INSTANCE.getSize();
  const isGroupCluster = type === "group-round-cluster";
  const isListCluster = type === "marker-list-cluster";
  let popupWidth = 340;
  let pointWidth = popupWidth + 50;
  let popupHeight = hasProject ? 430 : 120;
  let offsetPopup = {x: 0, y: 0};
  if (isGroupCluster) {
    offsetPopup.y = hasProject ? 250 : 145;
  } else if (isListCluster) {
    offsetPopup.y = -10;
  } else if (type === "property") {
    offsetPopup.y = hasProject ? 280 : 104;
  }
  //console.log(popupHeight, type)
  if ((mapSize.x - markerPos.x) > pointWidth || markerPos.x > pointWidth) {
    if ((popupWidth) > (mapSize.x - markerPos.x)) {
      // left, center
      offsetPopup.x = -200;
    } else {
      // right, center
      offsetPopup.x = isListCluster ? 238 : 215;
    }
    if (popupHeight > (mapSize.y - markerPos.y)) {
      offsetPopup.y = 60;
    } else if ((mapSize.y - markerPos.y) > 500) {
      offsetPopup.y = hasProject ? 210 : 124
    } else {
      offsetPopup.y = isGroupCluster ? (hasProject ? 200 : 70) : 30;
    }
  } else {
    if (popupHeight > (mapSize.y - markerPos.y)) {
      if (isListCluster) {
        offsetPopup.y = hasProject ? 240 : -6;
      } else {
        offsetPopup.y = -4;
      }
    } else if ((mapSize.y - markerPos.y) > 500) {
      offsetPopup.y = hasProject ? 210 : 124
    }
  }

  return {
    offsetX: offsetPopup.x,
    offsetY: offsetPopup.y,
  };
}

/**
 * Set localstorage for checked default tenure enquiry
 * */
export const setTenureRequestStorage = () => {
  const tenure = document.getElementById('filter_tenure') as HTMLInputElement;
  if (tenure) {
    const tenureRequest =  tenure.value === 'sale' ? 'buy' : 'rent';
    localStorage.setItem('tenure_request', tenureRequest);
  }
}
